import React, { Component } from "react";

import { connect } from "react-redux";
import { updateUser } from "../actions/sys";

import SysService from "../services/sys.service";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Timeline } from "primereact/timeline";
import { BlockUI } from "primereact/blockui";
import { withTranslation, Trans } from "react-i18next";
import WaitDialog from "../common/WaitDialog";
import { isAdmin } from "../common/token-check";

import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class Profile extends Component {

  constructor(props) {

    super(props);

    this.state = {
      content: "",
      timelineList: [],
      saveButtonStatus: false,
      loading: false,
      blockedPanel: false,
    };

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);

    this.onInputChange = this.onInputChange.bind(this);

  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || "";
    let content = { ...this.state.content };
    content[`${name}`] = val;

    this.setState({ saveButtonStatus:true, content });
  }

  accept() {

    this.setState({ loading: true });

    if (
      this.state.content.firstName.trim() === "" ||
      this.state.content.lastName.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnProfile1"/>);
      return;
    }

    this.props
      .dispatch(updateUser(this.state.content.username, this.state.content.firstName, this.state.content.lastName))
      .then(() => {
        this.setState({
          loading: false,
          saveButtonStatus: false
        });

        toast.success(<Trans i18nKey={this.props.message}/>);
        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message}/>);
      });
  }

  reject() {}

  componentDidMount() {

    this.setState({ loading: true });

    SysService.getUserInfo().then(
      (response) => {

        this.setState({
          content: response.data.users,
          timeline: response.data.timelineList,
          blockedPanel: false,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
          loading: false,
        });
        toast.error(<Trans i18nKey={this.state.content}/>);
        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    window.scrollTo(0, 0);
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <>
          <div className="grid ">
            <div className="col-12 md:col-8 lg:col-8 mb-2 centered">
              <BlockUI blocked={this.state.blockedPanel}>
                <Panel header={t("profile")}>
                  <br />

                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                    <div className="grid">
                      <div className="col-12 md:col-6 lg:col-6 mb-2">
                        <div className="block text-700 mb-2">
                          <label htmlFor="username" className="platformColor">{t("userName")}:</label>
                          <div className="block text-700 text-xl mb-2">
                            <strong>{this.state.content.username}</strong>
                          </div>
                        </div>
                      </div>


                    </div>

                    <hr />

                    <div className="grid mb-4">
                      <div className="col-12 md:col-6 lg:col-6 mb-2">
                        <div className="p-float-label">
                          <InputText
                            id="pi1"
                            value={this.state.content.firstName}
                            onChange={(e) => this.onInputChange(e, "firstName")}
                            maxLength={50}
                            className="w-full p-inputtext"
                          />
                          <label htmlFor="pi1" className="platformColor">{t("firstName")}</label>
                        </div>
                      </div>

                      <div className="col-12 md:col-6 lg:col-6 mb-0">
                        <div className="p-float-label">
                          <InputText
                            id="pi2"
                            value={this.state.content.lastName}
                            onChange={(e) => this.onInputChange(e, "lastName")}
                            maxLength={50}
                            className="w-full p-inputtext"
                          />
                          <label htmlFor="pi2" className="platformColor">{t("lastName")}</label>
                        </div>
                      </div>

                    </div>

                    {!isAdmin() && (
                    <div className="col-3 centered">
                      <Button
                        label={t("save")}
                        disabled={!this.state.saveButtonStatus}
                        onClick={() => this.setState({ visible: true })}
                        icon="pi pi-save"
                        className="w-full p-button-mb mb-4"
                      />

                      <ConfirmDialog
                        visible={this.state.visible}
                        onHide={() => {
                          this.setState({ visible: false });
                        }}
                        message={t("confirmMessage")}
                        header={t("confirm")}
                        icon="pi pi-exclamation-triangle"
                        accept={this.accept}
                        reject={this.reject}
                      />
                    </div>
                    )}
                  </div>
                </Panel>

              </BlockUI>
            </div>
            <div className="col-12 md:col-3 lg:col-3 mb-2">
              <BlockUI blocked={this.state.blockedPanel}>
                <Panel header={t("timeline")} toggleable>
                  <br />

                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round mb-2">
                    <Timeline
                      value={this.state.timeline}
                      opposite={(item) => t(item.event)}
                      content={(item) => item.date}
                    />
                  </div>
                  <div className="text-xs">
                    <i>{t("cetTime")}</i>
                  </div>
                </Panel>
              </BlockUI>
            </div>
          </div>

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />
        </>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Profile));
