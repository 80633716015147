import React, { Component } from "react";

import { ScrollTop } from "primereact/scrolltop";
import WaitDialog from "../common/WaitDialog";
import { withTranslation } from "react-i18next";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import { isAdmin } from "../common/token-check";

class Home extends Component {

  constructor(props) {

    super(props);

    this.state = {
      user: "",
      loading: false,
      blockedPanel: false,
    };

  }


  componentDidMount() {


    if (isAdmin()) {

    } else {

    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <ScrollTop />

        {isAdmin() && (
          <>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3" >
                        Ongoing project
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          6
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          2022-07-26
                        </span>{" "}
                        /latest new project date
                      </div>
                    </div>
                    <div
                     className="flex align-items-center justify-content-center bg-gray-100 border-round"
                     style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-folder text-black-500 text-3xl"></i>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Completed Project 
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          3
                        </span>{" "}
                       
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          Leicester
                        </span>{" "}
                        /lastest completed project
                      </div>                                                     
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-check-square text-black-500 text-3xl"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Users
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          5
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          Leicester
                        </span>{" "}
                        /latest user
                      </div>
                      
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-user text-black-500 text-3xl"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Energy saving
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                         1200 £/year
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          520
                        </span>{" "}
                        /last project
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-sun text-black-500 text-4xl"></i>
                    </div>
                  </div>
                </div>
              </div>              

            </div>
            <br />
          </>
        )}


        {!isAdmin() && (
          <>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                    <span className="block text-700 font-medium text-xl mb-3" >
                        Ongoing project
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          6
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          2022-07-26
                        </span>{" "}
                        /latest new project date
                      </div>
                    </div>
                    <div
                     className="flex align-items-center justify-content-center bg-gray-100 border-round"
                     style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-folder text-black-500 text-3xl"></i>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Completed Project 
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          3
                        </span>{" "}
                       
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          Leicester
                        </span>{" "}
                        /lastest completed project
                      </div>                                                     
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-check-square text-black-500 text-3xl"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Users
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                          5
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          Leicester
                        </span>{" "}
                        /latest user
                      </div>
                      
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-user text-black-500 text-3xl"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 200 }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-700 font-medium text-xl mb-3">
                        Energy saving
                      </span>
                      <div className="text-900 font-medium text-l">
                        <span className="text-black-500 font-large text-7xl">
                         1200 £/year
                        </span>{" "}
                        
                      </div>
                      <div className="text-900 font-medium text-l">
                        <span className="text-green-500 font-medium text-xl">
                          520
                        </span>{" "}
                        /last project
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-gray-100 border-round"
                      style={{ width: "4.0rem", height: "4.0rem" }}
                    >
                      <i className="pi pi-sun text-black-500 text-4xl"></i>
              
                    </div>
                  </div>
                </div>
              </div>              

            </div>
            <br />
          </>
        )}
        

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />


        <br />
      </div>
    );
  }
}

export default withTranslation()(Home);
