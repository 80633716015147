import React, { Component } from "react";
import { ScrollTop } from "primereact/scrolltop";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { withTranslation, Trans } from "react-i18next";
import WaitDialog from "../common/WaitDialog";
import SysService from "../services/sys.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import "../resources/main.css";

class Index extends Component {

    constructor(props) {

        super(props);

        this.state = {
            model: "",
            loading: false,
            blockedPanel: false,
        };
    }

    checkProduct = () => {

        console.log("geldi");

        this.setState({ loading: true });
    
        if (
          this.state.model == null ||
          this.state.model === "" 
        ) {
          this.setState({ loading: false });
          toast.warning(<Trans i18nKey="ctModelRequired"/>);
          return;
        }
    
        /*
        if (this.state.response === "" || this.state.response === null) {
          this.setState({ loading: false });
          toast.warning(<Trans i18nKey="check_captcha"/>);
          this.recaptcha.reset();
          return;
        }
        */
    
        const { history } = this.props;
    

        SysService.getProductByModel(this.state.model).then(
          (response) => {            
            history.push("/product/"+response.data.model);
            window.location.reload();
          },
          (error) => {
            this.setState({
              loading: false,
              content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),              
            });
            toast.error(<Trans i18nKey={this.state.content}/>);
          });
      }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const { t } = this.props;

        return (
            <div>

                <ScrollTop />

                <div className="col-11 centered">
      <>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
            <div className="text-center text-900 text-xl font-medium">
              {t("welcome")}!
            </div>
            <div className="text-center text-600 font-medium line-height-3 mb-6">
              {t("ctTxt1")}
            </div>
            <div>
                <label
                  htmlFor="model"
                  className="block text-900 font-medium mb-2"
                >
                  {t("productModel")}:
                </label>
                <InputText
                  id="model"
                  type="text"
                  className="w-full p-inputtext-sm block mb-2"
                  value={this.state.model}
                  onChange={(e) => this.setState({ model: e.target.value })}
                />
            </div>

            <div className="col-12 md:col-6 lg:col-6 mb-0 centered">


                <br />

                <Button
                  label={t("find")}
                  icon="pi pi-search"
                  className="w-full p-button-mb mb-4"
                  onClick={this.checkProduct}
                />
            </div>
          </div>

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />
        </div>
      </>
      </div>

                

            </div>

        );
    }
}

export default withTranslation()(Index);
