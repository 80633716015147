import React, { Component } from "react";
import { ScrollTop } from "primereact/scrolltop";
import { TabView, TabPanel } from "primereact/tabview";
import WaitDialog from "../common/WaitDialog";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { withTranslation, Trans } from "react-i18next";
import SysService from "../services/sys.service";
import { Card } from "primereact/card";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import "../resources/main.css";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: "",
      loading: false,
      dialogStatus: false,
      result: "",
      blockedPanel: false,
      obj: null,
      a1: null,
      a2: null,

      q1: "",
      q2: "",
      q3: "",
      q5: "",
      q6: "",
      q7: "",

      questionData: [],
      questionData2: [],

      activeIndex: 0, // şuanki adımı
      nextButtonDisable: true,
      questionsTextList: [],
      answersList: [],
      qid: "",
      productMinLifeSpan: false,
      productMinLifeSpanText: "",
      productCondition:""
    };
  }

  dateDiffInYears = (givenDate) => {
    var ageDifMs = Date.now() - givenDate;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  calculateLifeSpan = () => {
    let dateNow = new Date().getFullYear();
    console.log(dateNow);

    let date = this.state.product.dateOfUse;

    let lifeSpan = parseInt(this.state.product.minLifeSpan);

    if (date !== null && date !== undefined) {
      console.log(date);

      let dateLocal = new Date(date).getFullYear();
      console.log(dateLocal);

      let diff = dateNow - dateLocal;
      console.log("diff:", diff);
      console.log("life span:", lifeSpan);
      console.log(diff - lifeSpan);
      //let years = new Date(new Date() - new Date(date)).getFullYear() - 1970;
      //console.log(years);
      //console.log(typeof(years));
      //console.log(typeof(this.state.product.minLifeSpan));

      //let yearVal = years;

      if (diff < lifeSpan) {
        this.setState({
          productMinLifeSpan: true,
          productMinLifeSpanText: "Usage age is less than minimum life span",
        });
      } else {
        this.setState({
          productMinLifeSpanText:
            "Usage age is not less than minimum life span",
        });
      }
    }
  };

  calcDate = (date1, date2, t) => {
    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    if (date1_time_stamp > date2_time_stamp) {
      calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
      calc = new Date(date2_time_stamp - date1_time_stamp);
    }

    const calcFormatTmp =
      calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();

    const calcFormat = calcFormatTmp.split("-");

    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

    const yrsTxt = ["year", "years"];
    const mnthsTxt = ["month", "months"];
    const daysTxt = ["day", "days"];

    const total_days =
      years_passed * 365 + months_passed * 30.417 + days_passed;
    const total_secs = total_days * 24 * 60 * 60;
    const total_mins = total_days * 24 * 60;
    const total_hours = total_days * 24;
    const total_weeks = total_days >= 7 ? total_days / 7 : 0;

    const result =
      (years_passed == 1
        ? years_passed + " " + t(yrsTxt[0]) + " "
        : years_passed > 1
        ? years_passed + " " + t(yrsTxt[1]) + " "
        : "") +
      (months_passed == 1
        ? months_passed + " " + t(mnthsTxt[0])
        : months_passed > 1
        ? months_passed + " " + t(mnthsTxt[1]) + " "
        : "") +
      (days_passed == 1
        ? days_passed + " " + t(daysTxt[0])
        : days_passed > 1
        ? days_passed + " " + t(daysTxt[1])
        : "");

    return result.trim();
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  handleChangeBannerImageUpload = () => {
    if (
      document.getElementById("fupload").files == null ||
      document.getElementById("fupload").files[0] == null
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);
      document.getElementById("fupload").value = "";
      return;
    }

    const maxAllowedSize = 1024 * 1024;

    if (document.getElementById("fupload").files[0].size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSize" />);
      document.getElementById("fupload").value = "";

      return;
    }

    const documentType =
      document
        .getElementById("fupload")
        .files[0].name.toLowerCase()
        .endsWith(".jpg") ||
      document
        .getElementById("fupload")
        .files[0].name.toLowerCase()
        .endsWith(".png") ||
      document
        .getElementById("fupload")
        .files[0].name.toLowerCase()
        .endsWith(".gif");

    if (!documentType) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="checkFileType" />);

      return;
    }

    document.getElementById("outputU").src = window.URL.createObjectURL(
      document.getElementById("fupload").files[0]
    );

    this.setState({
      obj: window.URL.createObjectURL(
        document.getElementById("fupload").files[0]
      ),
    });
  };

  checkAnswers = () => {
   
    // console.log("kkslaksalkalkslkalkslakslaksl");

    // console.log(this.state.questionData[0].answerList[0].value);
    // console.log(this.state.questionData[1].answerList[1].value);
    // console.log(this.state.questionData[2].answerList[1].value);
    // console.log(this.state.questionData[3].answerList[1].value);
    // console.log(this.state.questionData[4].answerList[1].value);
    // console.log(this.state.questionData[4].answerList[2].value);
    // console.log(this.state.questionData[0].answerList[0].value);

    if (
      this.state.questionData[0].answerList[0].value === true &&
      this.state.questionData[1].answerList[1].value === true &&
      this.state.questionData[2].answerList[1].value === true &&
      this.state.questionData[3].answerList[1].value === true &&
      (this.state.questionData[4].answerList[1].value === true ||
        this.state.questionData[4].answerList[2].value === true) &&
      this.state.questionData[5].answerList[0].value === true
    ) {
      this.setState({
        productCondition:"Looks new"
      })
      
    } else {
      this.setState({
        productCondition:"Old rusted and likely broken"
      })
     
    }

    // this.setState({
    //   productCondition:""
    // })
  };

  showResult = () => {
    this.setState({ loading: true });
    this.calculateLifeSpan();
    this.checkAnswers(); 
    // if (
    //   this.state.a1 == null ||
    //   this.state.a1 === "" ||
    //   this.state.a2 == null ||
    //   this.state.a2 === ""
    // ) {
    //   this.setState({ loading: false });
    //   toast.warning(<Trans i18nKey="answersRequired" />);
    //   return;
    // }

    // if (
    //   (this.state.a1 === "A" || this.state.a1 === "B") &&
    //   this.state.a2 === "A" &&
    //   this.dateDiffInYears(new Date(this.state.product.manufacturingDate)) <
    //     10 &&
    //   (this.state.product.economicValue === "M" ||
    //     this.state.product.economicValue === "H") &&
    //   (this.state.product.demand === "M" || this.state.product.demand === "H")
    // ) {
    //   this.state.result = <Trans i18nKey="result1" />;
    // } else {
    //   this.state.result = <Trans i18nKey="result2" />;
    // }

    // this.setState({ loading: false, dialogStatus: true });

    // document.getElementById("outputM").src =
    //   document.getElementById("outputU").src;

    //yes
    //no
    //no
    //no
    //dirty or clean
    //yes

    console.log(this.state.questionData[0].answerList[1].value);
    console.log(this.state.questionData[1].answerList[0].value);
    console.log(this.state.questionData[2].answerList[0].value);
    console.log(this.state.questionData[3].answerList[0].value);
    console.log(this.state.questionData[4].answerList[0].value);
    console.log(this.state.questionData[4].answerList[0].value);
    console.log(this.state.questionData[5].answerList[1].value);
    console.log(this.state.product.demand);
    console.log(this.state.product.economicValue);
    console.log(this.state.productMinLifeSpan);
    // Q1: No
    // Q2:Yes 
    // Q3:Yes 
    // Q4: Yes
    // Q5:Very Dirty 
    // Q6:No
    if (
      this.state.questionData[0].answerList[1].value === true &&
      this.state.questionData[1].answerList[0].value === true &&
      this.state.questionData[2].answerList[0].value === true &&
      this.state.questionData[3].answerList[0].value === true &&
      this.state.questionData[4].answerList[0].value === true &&
      this.state.questionData[5].answerList[1].value === true
       &&
      
       this.state.product.economicValue !== "M" 
       &&
       this.state.product.demand !== "M"
       // this.state.productMinLifeSpan &&
    ) {
      this.state.result = <Trans i18nKey="result2" />;
      
    } else {
      this.state.result = <Trans i18nKey="result1" />;
    }
    this.setState({ loading: false, dialogStatus: true });
    // document.getElementById("outputM").src =
    //   document.getElementById("outputU").src;
  };

  hideDialog = () => {
    this.setState({
      dialogStatus: false,
    });
  };

  componentDidMount() {
    this.enableNextButton();
    if (this.props.location.state && this.props.location.state.id) {
      this.setState({ qid: this.props.location.state.id });
    }

    SysService.getProductByModel(this.props.match.params.model).then(
      (response) => {
        this.setState({
          product: response.data,
          blockedPanel: false,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
          loading: false,
        });
        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    SysService.getQuestions().then(
      (response) => {
        console.log("backendden gelen", response.data);
        this.setState({ questionData: response.data.questionData });

        // console.log("JSON parser: ", JSON.parse(response.data[0].questionData));
        // let result = JSON.parse(response.data[0].questionData);
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          //blockedPanel: true,
          loading: false,
        });
        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    // fetch("/data/formTempData.json")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("JSON Verileri:", data);
    //     this.setState({ questionData: data });
    //   })
    //   .catch((error) => {
    //     console.error("Failed to fetch data: ", error);
    //   });

    window.scrollTo(0, 0);
  }

  checkRadioButtonsSelected = () => {
    const currentQuestion = this.state.questionData[this.state.activeIndex];

    if (currentQuestion && currentQuestion.answerList) {
      const currentQuestionAnswers = currentQuestion.answerList;
      const isAnyRadioButtonSelected = currentQuestionAnswers.some(
        (answer) => answer.value
      );
      return isAnyRadioButtonSelected;
    }

    return false;
  };

  checkIfCurrentQuestionAnswered = () => {
    const currentQuestion = this.state.questionData[this.state.activeIndex];
    if (currentQuestion && currentQuestion.answerList) {
      const isAnyRadioButtonSelected = currentQuestion.answerList.some(
        (answer) => answer.value
      );
      //console.log("isAnyRadioButtonSelected:", isAnyRadioButtonSelected);
      return isAnyRadioButtonSelected;
    }
    return false;
  };

  stepNext = () => {
    if (this.state.activeIndex < this.state.questionData.length - 1) {
      if (this.checkIfCurrentQuestionAnswered()) {
        this.setState((prevState) => ({
          activeIndex: prevState.activeIndex + 1,
        }));
      } else {
        toast.warning("Please answer the current question before proceeding.");
      }
    }
  };

  handleChange = (answerIndex) => {
    const updatedQuestionData = [...this.state.questionData];
    updatedQuestionData[this.state.activeIndex].answerList.forEach(
      (answer, index) => {
        answer.value = index === answerIndex;
      }
    );

    console.log("Updated Question data :", updatedQuestionData);
    this.setState({ questionData: updatedQuestionData }, () => {
      this.enableNextButton();
    });
  };

  enableNextButton = () => {
    if (this.state.questionData.length > 0) {
      const currentQuestionAnswers =
        this.state.questionData[this.state.activeIndex].answerList;
      const isAnyRadioButtonSelected = currentQuestionAnswers.some(
        (answer) => answer.value
      );
      // console.log("Current Question Answers: ", currentQuestionAnswers);

      const isLastQuestion =
        this.state.activeIndex === this.state.questionData.length - 1;
      this.setState({
        nextButtonDisable: !isAnyRadioButtonSelected && !isLastQuestion,
      });
    }
  };

  enableGetRecommendationButton = () => {
    const currentQuestionAnswers =
      this.state.questionData[this.state.activeIndex].answerList;
    const isCurrentQuestionAnswered = currentQuestionAnswers.some(
      (answer) => answer.value
    );

    this.setState({
      nextButtonDisable: !isCurrentQuestionAnswered,
    });
  };

  stepPrev = () => {
    if (this.state.activeIndex > 0) {
      this.setState((prevState) => ({
        activeIndex: prevState.activeIndex - 1,
      }));
      this.enableNextButton();
    }
  };

  stepSaveAndNext = () => {};

  setAnswerList = () => {
    let answerList = [...this.state.questionData[0].answerList];

    for (let i = 0; i < answerList.length; i++) {
      if (answerList[i].value === true) {
        this.setState({ nextButtonDisable: false });
        break;
      }
    }
  };

  nextStepDisable = () => {
    if (
      this.state.activeIndex >= this.state.questionData.length - 1 &&
      this.state.nextButtonDisable === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderAnswer = () => {
    return (
      <div>
        {this.state.questionData[this.state.activeIndex].answerList.map(
          (item, answerIndex) => (
            <div key={answerIndex} className="p-field-radiobutton mb-3">
              <RadioButton
                inputId={`answer-${answerIndex}`}
                name={`answer-${this.state.activeIndex}`}
                value={answerIndex}
                onChange={() => {
                  this.handleChange(answerIndex);
                }}
                checked={item.value === true}
              />{" "}
              <label htmlFor={`answer-${answerIndex}`}>{item.answer}</label>
            </div>
          )
        )}
      </div>
    );
  };

  isLastQuestionAnswered = () => {
    const { questionData, activeIndex } = this.state;
    if (questionData[activeIndex] && questionData[activeIndex].answerList) {
      const lastQuestionAnswers = questionData[activeIndex].answerList;
      return lastQuestionAnswers.some((answer) => answer.value);
    }
    return false;
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <ScrollTop />

        <div className="col-12 centered">
          <div className="flex align-items-center justify-content-center mb-4">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-8">
              <div className="text-center text-900 text-xl font-medium mb-4">
                {t("productDetails")}
              </div>
              <br /> <br />
              {/* <div class="row justify-content-between">
                <div class="col-4"> </div>

                <div class="col-4">
                  <img src="/assets/img/productImage.png" />
                </div>
              </div> */}
              <div className="grid justify-content-between">
                <div className="col-12" style={{ alignItems: "flex-end" }}>
                  <img
                    src="/assets/img/productImage.png"
                    //style={{ alignItems: 'flex-end', width: 80, height: 30 }}
                    style={{
                      position: "absolute",
                      top: this.props.top,
                      right: "350px",
                    }}
                  />
                </div>

                <form>
                  {/* <img
                    src="/assets/img/productImage.png"
                    style={{
                      float:"right"
                  }}
                  /> */}

                  {/* <div className="col-12 md-3 lg-3 mb-4 mr-6"> */}
                  <div class="col-12 md-3 lg-3 mb-4 mr-6 ">
                    <label
                      htmlFor="model"
                      className="block text-900 font-medium mb-2"
                    >
                      <strong>{t("brandName")}:</strong>{" "}
                      {this.state.product.brand}
                    </label>
                  </div>

                  <div className="col-12 md-3 lg-3 mb-4 mr-6">
                    <label
                      htmlFor="model"
                      className="block text-900 font-medium mb-2"
                    >
                      <strong>{t("category")}:</strong>{" "}
                      {this.state.product.category}
                    </label>
                  </div>
                </form>
                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="colour"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("colour")}:</strong> {this.state.product.colour}
                  </label>
                </div>

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="colour"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("productCode")}:</strong>{" "}
                    {this.state.product.productCode}
                  </label>
                </div>

                {/* <div className="row col-12">
                  <div class="col-md-4">
                    <label
                      htmlFor="colour"
                      className="block text-900 font-medium mb-2"
                    >
                      <strong>{t("productCode")}:</strong>{" "}
                      {this.state.product.productCode}
                    </label>
                  </div>

                  <div class="col-2 col-md-4 offset-md-4">
                    <img src="/assets/img/productImage.png" />
                  </div>
                </div> */}

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="ean"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("ean")}:</strong> {this.state.product.ean}
                  </label>
                </div>

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="manufacturingLocation"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("manufacturingLocation")}:</strong>{" "}
                    {this.state.product.manufacturingLocation}
                  </label>
                </div>

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="demand"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("demand")}:</strong> {this.state.product.demand}
                  </label>
                </div>

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="economicValue"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("economicValue")}:</strong>{" "}
                    {this.state.product.economicValue}
                  </label>
                </div>

                <div className="col-12 md-3 lg-3 mb-4 mr-6">
                  <label
                    htmlFor="manufacturingDate"
                    className="block text-900 font-medium mb-2"
                  >
                    <strong>{t("manufacturingDate")}:</strong>{" "}
                    {this.formatDate(this.state.product.manufacturingDate)}
                  </label>
                  <br />
                  <i>
                    ({t("age")}:{" "}
                    {this.calcDate(
                      new Date(),
                      this.formatDate(this.state.product.manufacturingDate),
                      t
                    )}
                    )
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-items-center justify-content-center mb-4">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-8">
              <label style={{ fontWeight: "bolder" }}>
                {"Please answer the following questions"}
              </label>
              <br />
              <br />
              <div></div>
              <TabView
                closable={true}
                activeIndex={this.state.activeIndex}
                onTabChange={(e) => this.setState({ activeIndex: e.index })}
              >
                {this.state.questionData.map((item, index) => (
                  <TabPanel
                    key={index}
                    header={`Question ${index + 1}`}
                    disabled={true}
                  >
                    {item.questionText}
                    <br />
                    <br />
                    {this.renderAnswer()}
                  </TabPanel>
                ))}
              </TabView>

              <br />
              <div style={{ display: "flex" }}>
                <Button
                  label="Previous"
                  onClick={() => this.stepPrev()}
                  disabled={this.state.activeIndex === 0}
                  className="p-button-primary"
                />
                &nbsp;
                <Button
                  label="Next"
                  onClick={() => this.stepNext()}
                  className="p-button-success"
                  disabled={
                    !this.checkIfCurrentQuestionAnswered() ||
                    this.state.activeIndex ===
                      this.state.questionData.length - 1
                  }
                />
                &nbsp;
                <Button
                  className="p-button-mb"
                  style={{ marginLeft: "auto" }}
                  label={t("showResult")}
                  icon="pi pi-question"
                  onClick={this.showResult}
                  disabled={
                    !this.checkIfCurrentQuestionAnswered() ||
                    this.state.activeIndex !==
                      this.state.questionData.length - 1
                  }
                />
              </div>
            </div>
          </div>

          {/* <Dialog
            visible={this.state.dialogStatus}
            style={{ width: "550px" }}
            header={t("result")}
            modal
            onHide={this.hideDialog}
          >
            <div className="cols-12 mb-4">
              <div className="grid">
                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  &nbsp;
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {t("status")}
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {t("prepareForReuse")}
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {t("sendForRecycling")}
                </div>

                <div className="col-3">{t("ageOfDevice")}</div>

                <div className="col-3">
                  {this.calcDate(
                    new Date(),
                    this.formatDate(this.state.product.manufacturingDate),
                    t
                  )}
                  
                </div>

                <div className="col-3 text-center">
                  {this.dateDiffInYears(
                    new Date(this.state.product.manufacturingDate)
                  ) < 10 && <img src="/assets/img/check.png" />}
                  {this.dateDiffInYears(
                    new Date(this.state.product.manufacturingDate)
                  ) >= 10 && <img src="/assets/img/error.png" />}
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div>

                <div className="col-3">{t("stateOfDevice")}</div>

                <div className="col-3">
                  {this.state.a1 === "A" && t("a11")}
                  {this.state.a1 === "B" && t("a12")}
                  {this.state.a1 === "C" && t("a13")}
                  {this.state.a1 === "D" && t("a14")}
                </div>

                <div className="col-3 text-center">
                  {this.state.a1 === "A" && <img src="/assets/img/check.png" />}
                  {this.state.a1 === "B" && <img src="/assets/img/check.png" />}
                  {this.state.a1 === "C" && <img src="/assets/img/error.png" />}
                  {this.state.a1 === "D" && <img src="/assets/img/error.png" />}
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div>

                <div className="col-3">{t("electricalCables")}</div>

                <div className="col-3">
                  {this.state.a2 === "A" && t("yes")}
                  {this.state.a2 === "B" && t("no")}
                </div>

                <div className="col-3 text-center">
                  {this.state.a2 === "A" && <img src="/assets/img/check.png" />}
                  {this.state.a2 === "B" && <img src="/assets/img/error.png" />}
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div>

                <div className="col-3">{t("demand")}</div>

                <div className="col-3">{this.state.product.demand}</div>

                <div className="col-3 text-center">
                  {this.state.product.demand === "M" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.demand === "H" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.demand === "L" && (
                    <img src="/assets/img/error.png" />
                  )}
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div>

                <div className="col-3">{t("economicValue")}</div>

                <div className="col-3">{this.state.product.economicValue}</div>

                <div className="col-3 text-center">
                  {this.state.product.economicValue === "M" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.economicValue === "H" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.economicValue === "L" && (
                    <img src="/assets/img/error.png" />
                  )}
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div>
              </div>
            </div>

            <div className="text-center">
              <div
                style={{ display: this.state.obj != null ? "block" : "none" }}
              >
                <img
                  id="outputM"
                  src={this.state.obj}
                  width="200"
                  height="150"
                  alt=""
                />{" "}
                &nbsp;
                <br />
                <br />
              </div>
              {t("recommendation")}:
            </div>

            <div
              className="text-center"
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                color: "green",
                fontSize: "20px",
              }}
            >
              {this.state.result}
            </div>
          </Dialog> */}

          <Dialog
            visible={this.state.dialogStatus}
            style={{ width: "650px" }}
            header={t("result")}
            modal
            onHide={this.hideDialog}
          >
            <div className="cols-12 mb-4">
              <div className="grid">
                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  &nbsp;
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {t("status")}
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {"Test For Reuse "}
                </div>

                <div
                  className="col-3"
                  style={{ fontWeight: "bold", backgroundColor: "#fdfdfd" }}
                >
                  {t("sendForRecycling")}
                </div>

                <div className="col-3">{"Product Condition"}</div>

                <div className="col-3">
                {this.state.productCondition }
               
                  {/* {this.calcDate(
                    new Date(),
                    this.formatDate(this.state.product.manufacturingDate),
                    t
                  )} */}

                 
                </div>

                <div className="col-3 text-center">
                {this.state.productCondition === "Looks new"  ? (
                    <img src="/assets/img/check.png" />
                  ) : (
                    <img src="/assets/img/error.png" />
                  )}
                

                  {/* {this.dateDiffInYears(
                    new Date(this.state.product.manufacturingDate)
                  ) < 10 && <img src="/assets/img/check.png" />}
                  {this.dateDiffInYears(
                    new Date(this.state.product.manufacturingDate)
                  ) >= 10 && <img src="/assets/img/error.png" />} */}
                </div>

                <div className="col-3 text-center">
                {this.state.productCondition === "Looks new"  ? (
                    <img src="/assets/img/error.png" />
                  ) : (
                    <img src="/assets/img/check.png" />
                  )}
                 
                </div>

                <div className="col-3">{"Product is in minimum life span"}</div>

                <div className="col-3">{this.state.productMinLifeSpanText}</div>

                <div className="col-3 text-center">
                  {this.state.productMinLifeSpan === false ? (
                    <img src="/assets/img/error.png" />
                  ) : (
                    <img src="/assets/img/check.png" />
                  )}
                </div>

                <div className="col-3 text-center">
                  {this.state.productMinLifeSpan === false ? (
                    <img src="/assets/img/check.png" />
                  ) : (
                    <img src="/assets/img/error.png" />
                  )}
                </div>

                {/* <div className="col-3">{t("electricalCables")}</div>

                <div className="col-3">
                  {this.state.a2 === "A" && t("yes")}
                </div>

                <div className="col-3 text-center">
                   <img src="/assets/img/check.png" />
                </div>

                <div className="col-3 text-center">
                  <img src="/assets/img/check.png" />
                </div> */}

                <div className="col-3">{t("demand")}</div>

                <div className="col-3">{this.state.product.demand}</div>

                <div className="col-3 text-center">
                  {this.state.product.demand === "M" ? 
                    <img src="/assets/img/check.png" />
                  :                  
                    <img src="/assets/img/error.png" />
                  }

                  {/* {this.state.product.demand === "H" && (
                    <img src="/assets/img/check.png" />
                  )} */}
                  {/* {this.state.product.demand === "L" && (
                    <img src="/assets/img/error.png" />
                  )} */}
                </div>

                <div className="col-3 text-center">
                  {this.state.product.demand === "M" && (
                    <img src="/assets/img/error.png" />
                  )}

                   {this.state.product.demand !== "M" && (
                    <img src="/assets/img/check.png" />
                  )}

                  {/* {this.state.product.demand === "H" && (
                    <img src="/assets/img/error.png" />
                  )}
                  {this.state.product.demand === "L" && (
                    <img src="/assets/img/check.png" />
                  )} */}
                </div>

                <div className="col-3">{t("economicValue")}</div>

                <div className="col-3">{this.state.product.economicValue}</div>

                <div className="col-3 text-center">
                  {this.state.product.economicValue === "M" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.economicValue === "H" && (
                    <img src="/assets/img/check.png" />
                  )}
                  {this.state.product.economicValue === "L" && (
                    <img src="/assets/img/error.png" />
                  )}
                </div>

                <div className="col-3 text-center">
                  {this.state.product.economicValue === "M" && (
                    <img src="/assets/img/error.png" />
                  )}
                  {this.state.product.economicValue === "H" && (
                    <img src="/assets/img/error.png" />
                  )}
                  {this.state.product.economicValue === "L" && (
                    <img src="/assets/img/check.png" />
                  )}
                </div>
              </div>
            </div>

            <div className="text-center">
              <div
                style={{ display: this.state.obj != null ? "block" : "none" }}
              >
                <img
                  id="outputM"
                  src={this.state.obj}
                  width="200"
                  height="150"
                  alt=""
                />{" "}
                &nbsp;
                <br />
                <br />
              </div>
              {t("recommendation")}:
            </div>

            <div
              className="text-center"
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                color: "green",
                fontSize: "20px",
              }}
            >
              {this.state.result}
            </div>
          </Dialog>

          <WaitDialog loading={this.state.loading} lang={t} />

          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="colored"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Product);
