import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { ScrollTop } from "primereact/scrolltop";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation, Trans, useTranslation } from "react-i18next";

import SysService from "../services/sys.service";

const FormDesign = (t, props) => {
  //const [questions, setQuestions] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [newProject, setNewProject] = useState(false);
  // const [questionList, setQuestionList] = useState([]);

  const [questionText, setQuestionText] = useState("");
  const [answerList, setAnswerList] = useState([]);
  const [answer, setAnswer] = useState("");

  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");

  const showDialog = () => {
    setQuestionText("");
    setAnswerList([]);
    setDisplayDialog(true);
  };

  const saveQuestion = () => {
    if (questionText.trim() !== "" && answerList.length > 0) {
      //questionList.push({ questionText, answerList });
      questionData.push({ questionText, answerList });
      setQuestionText("");
      setAnswerList([]);
      setDisplayDialog(false);
    }
    //console.log(questions);
    //const myObjStr = JSON.stringify(questions, null, 2);
    //console.log("JSON File: ", myObjStr);
  };

  const addAnswer = () => {
    if (answer) {
      answerList.push({ answer, value: false });
      setAnswer("");
    }
  };

  const handleRadioChange = (questionIndex, answerIndex) => {
    const updatedQuestions = [...questionData];
    updatedQuestions[questionIndex].answerList.forEach((answer, index) => {
      answer.value = index === answerIndex;
    });
    setQuestionData(updatedQuestions);
    //console.log(questions);
    //const myObjStr = JSON.stringify(questions, null, 2);
    //console.log("JSON File: ", myObjStr);
  };

  const addNewQuestion = () => {
    if (questionData.length <= 0) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    } else {
      setLoading(true);

      const jsonQuestionObj = JSON.stringify({ questionData }, null, 2);
      console.log("JSON File: ", jsonQuestionObj);

      SysService.addNewQuestion(jsonQuestionObj).then(
        (response) => {
          toast.success(<Trans i18nKey={response.data.message} />);
          console.log("RESPONSE:", response.data.message);
        },
        (error) => {
          setLoading(false);
          setContent(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );

          toast.error(<Trans i18nKey={content} />);
        }
      );
    }
  };

  useEffect(() => {
    if (submitted) {
      const jsonQuestionObj = JSON.stringify({ questionData }, null, 2);
      SysService.addNewQuestion(jsonQuestionObj)
        .then((response) => {
          toast.success(<Trans i18nKey={response.data.message} />);
          console.log("RESPONSE:", response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          setContent(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );

          toast.error(<Trans i18nKey={content} />);
        });
    }
  }, [submitted, newProject, props, questionData]);
  return (
    <>
      <div>
        <ScrollTop />

        <div className="col-12 centered">
          <div className="flex align-items-center justify-content-center mb-4">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-8">
              <div className="text-center text-900 text-xl font-medium mb-4">
                {"Design Form "}
              </div>
              <div>
                <div className="field col-12 mb-0">
                  <Button
                    label="Add Question"
                    icon="pi pi-plus"
                    onClick={showDialog}
                  />
                </div>

                <div className="col-12">
                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                    <DataTable value={questionData}>
                      <Column field="questionText" header="Question" />
                      <Column
                        field="answers"
                        header="Answers"
                        body={(rowData, { rowIndex }) => (
                          <ul>
                            {rowData.answerList.map((ans, index) => (
                              <div key={index}>
                                <RadioButton
                                  inputId={`answer_${rowIndex}_${index}`}
                                  name={`answer_${rowIndex}`}
                                  value={index}
                                  // onChange={() =>
                                  //   handleRadioChange(rowIndex, index)
                                  // }
                                  checked={ans.value}
                                />
                                {ans.answer}
                              </div>
                            ))}
                          </ul>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>

                <div className="field col-12 mb-0" style={{ display: "flex" }}>
                  <Button
                    className="p-button-mb "
                    style={{ marginLeft: "auto" }}
                    label="Save"
                    onClick={addNewQuestion}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          visible={displayDialog}
          style={{ width: "500px" }}
          onHide={() => setDisplayDialog(false)}
          header={"Add Question"}
          className="p-fluid"
        >
          <div className="p-grid p-fluid">
            <div className="p-float w-full mb-2">
              <label>Question</label>
              <InputText
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                placeholder={"please enter question"}
              />
            </div>

            <label>Answer</label>
            <div
              className="p-float w-full mb-2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <InputText
                value={answer}
                className="p-inputtext-sm block"
                style={{ width: "90%", marginRight: "10px" }}
                placeholder="Please enter option"
                onChange={(e) => setAnswer(e.target.value)}
              />
              <Button
                icon="pi pi-plus"
                className="p-button-rounded"
                aria-label="User"
                disabled={answer !== "" ? false : true}
                onClick={addAnswer}
              />

              {/* <Button
                        label="+"
                        style={{ width: "10%" }}
                       // icon="pi pi-plus"

                        //style={{ marginLeft: "auto" }}
                        disabled={answer !== "" ? false : true}
                        onClick={addAnswer}
                        className="p-button-rounded p-button-mb "
                      /> */}
            </div>
            <div className="p-float w-full mb-2">
              <ul>
                {answerList.map((ans, index) => (
                  <li key={index}>{ans.answer}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-content-start px-2" id="dialogButton">
              <br />
              <br />
              <Button
                label="Save"
                style={{ width: "20%" }}
                icon="pi pi-check"
                className="p-button-mb"
                onClick={saveQuestion}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default withTranslation()(FormDesign);
