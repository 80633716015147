import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

class ProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [], // JSON verileri
      activeIndex: 0, // şuanki adımı 
    };
  }

  componentDidMount() {
    fetch("/data/formTempData.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("JSON Verileri:", data); 
        this.setState({ tableData: data });
      })
      .catch((error) => {
        console.error("Failed to fetch data: ", error);
      });
  }

  stepPrev() {
    if (this.state.activeIndex > 0) {
      this.setState((prevState) => ({
        activeIndex: prevState.activeIndex - 1,
      }));
    }
  }

  stepNext() {
    if (this.state.activeIndex < this.state.tableData.length - 1) {
      this.setState((prevState) => ({
        activeIndex: prevState.activeIndex + 1,
      }));
    }
  }

  stepSaveAndNext() {
   
  }

  handleChange(answerIndex) {
    const updatedTableData = [...this.state.tableData];
    updatedTableData[this.state.activeIndex].answers.forEach((answer, index) => {
      answer.value = index === answerIndex;
    });
    this.setState({ tableData: updatedTableData });
    console.log(this.state.tableData)
  }

  renderAnswer(rowData) {
    return (
      <div>
        {rowData.answers.map((item, answerIndex) => (
          <div key={answerIndex} className="p-field-radiobutton">
            <RadioButton
              inputId={`answer-${answerIndex}`}
              name={`answer-${this.state.activeIndex}`}
              value={answerIndex}
              onChange={() => this.handleChange(answerIndex)}
              checked={item.value === true}
            />
            <label htmlFor={`answer-${answerIndex}`}>{item.answer}</label>
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div>
        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
          {this.state.tableData.map((item, index) => (
            <TabPanel key={index} header={`Question ${index + 1}`}>
                {this.state.tableData[this.state.activeIndex].question}
                {this.renderAnswer(this.state.tableData[this.state.activeIndex])}
            </TabPanel>
          ))}
        </TabView>

        <Button label="Previous" onClick={() => this.stepPrev()} disabled={this.state.activeIndex <= 0} />
        <Button label="Next" onClick={() => this.stepNext()} disabled={this.state.activeIndex >= this.state.tableData.length - 1} />
        <Button label="Save and Next" onClick={() => this.stepSaveAndNext()} />
      </div>
    );
  }
}

export default ProductForm;
