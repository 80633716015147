import "bootstrap/dist/css/bootstrap.min.css";
import "./resources/main.css";

import React, { Component } from "react";
import { BrowserRouter, Router, Switch } from "react-router-dom";

import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import PrivateRoute from "./common/PrivateRoute";
import PublicRoute from "./common/PublicRoute";
import WaitDialog from "./common/WaitDialog";

import Login from "./components/login";
import ForgotPassword from "./components/forgotPassword";
import ChangePassword from "./components/changePassword";
import SetNewPassword from "./components/setNewPassword";
import VerifyEmail from "./components/verifyEmail";
import Product from "./components/product";
import FormDesign from "./components/formDesign";
import ProductForm from "./components/productForm";


import Index from "./components/index";
import PageNotFound from "./components/404";
import UnauthorizedPage from "./components/401";
import ForbiddenPage from "./components/403";
import Home from "./components/home";
import Profile from "./components/profile";
import Users from "./components/users";

import { Dropdown } from "primereact/dropdown";
import { clearMessage } from "./actions/message";
import { connect } from "react-redux";

import { history } from "./common/history";
import { isAdmin, isPlatformManager, isProjectManager } from "./common/token-check";
import jwt_decode from "jwt-decode";
import { logout } from "./actions/auth";
import { isLogin } from "./common/token-check";

import i18n from "./i18n";
import { withTranslation } from "react-i18next";

class App extends Component {

  constructor(props) {

    super(props);

    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      language: "",
      laName: "",
    };

    this.languages = [
      { name: "English", code: "en" },
      { name: "Türkçe", code: "tr" }
    ];

    this.menuItems1 = [];

    history.listen((location) => {
      props.dispatch(clearMessage());
    });
  }

  componentDidMount() {

    const user = this.props.user;
    
    if (user) {
      const decodedJwt = jwt_decode(user.token);
      user.fullname = decodedJwt.firstName + " " + decodedJwt.lastName;
      user.laName = decodedJwt.laName;

      this.setState({
        currentUser: user,
        laName: user.laName,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      currentUser: undefined,
    });
  }

  render() {

    const { t } = this.props;
    const user = this.props.user;

    return (
      <Router history={history}>
        <div>
          <center>
            <img
              src="/assets/img/ct-logo.png"
              width="18%"
              alt="CircThread"
              style={{ marginTop: "20px" }}
            />
          <br/><br/>
          {t("ctTxt")}
          <br/><br/>


          <Dropdown
                              id="idType"
                              value={this.state.language}
                              options={this.languages}
                              onChange={(e) => {i18n.changeLanguage(e.value); this.setState({ language: e.value })}}
                              optionLabel="name"
                              optionValue="code"
                              className=""
                            />          
          </center>

          <div className="col-12 md:col-12 lg:col-12 centered">
            <br />
            <BrowserRouter>
              <Switch>
                <PublicRoute component={UnauthorizedPage} path="/401" exact />
                <PublicRoute component={ForbiddenPage} path="/403" exact />
                <PublicRoute component={PageNotFound} path="/404" exact />
                <PublicRoute restricted={true} component={ForgotPassword} path="/forgotPassword" exact /> 
                <PublicRoute restricted={false} component={Index} path="/" exact />
                <PublicRoute component={Product} path="/product/:model" restricted={false} exact />         
                <PublicRoute component={FormDesign} path="/formDesign" exact />       
                <PublicRoute component={ProductForm} path="/productForm" exact />          
   
              </Switch>
            </BrowserRouter>

            <div className="text-center">
              <br />
              <br />
              <div className="mb-0">
              <img
                src="/assets/img/euflag.png"
                style={{ height: "30px" }}
                alt=""
              />{" "}
              {t("euTxt")}
              </div>

              <div>
              <span className="text-700 font-medium">
                {t("footerCopyright")}{" "}
                <img
                  src="/assets/img/ed-logo.png"
                  alt="Ekodenge"
                  height="100%"
                  className="mb-2"
                />
              </span>
              </div>
            </div>
          </div>

          <WaitDialog loading={this.state.loading} lang={t} />

          {<AuthVerify logOut={this.logOut} />}
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(App));
