export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAIL = "CHANGE_PASS_FAIL";
export const CHANGE_ROLE_SUCCESS = "CHANGE_ROLE_SUCCESS";
export const CHANGE_ROLE_FAIL = "CHANGE_ROLE_FAIL";
export const CHANGE_PROJECT_SUCCESS = "CHANGE_PROJECT_SUCCESS";
export const CHANGE_PROJECT_FAIL = "CHANGE_PROJECT_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAIL = "SET_NEW_PASSWORD_FAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";
export const RESEND_VERIFY_EMAIL_SUCCESS = "RESEND_VERIFY_EMAIL_SUCCESS";
export const RESEND_VERIFY_EMAIL_FAIL = "RESEND_VERIFY_EMAIL_FAIL";
export const LOGOUT = "LOGOUT";

export const ADD_NEW_PROJECT_SUCCESS = "ADD_NEW_PROJECT_SUCCESS";
export const ADD_NEW_PROJECT_FAIL = "ADD_NEW_PROJECT_FAIL";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
